import { v } from 'h3-valibot';

export const jsonParseBaseSchema = (jsonSchema: any) =>
  v.pipe(
    v.string(), // Startet mit einem String
    v.rawTransform(
      ({ dataset }) =>
        JSON.parse(dataset.value) as v.InferOutput<typeof jsonSchema>
    ), // JSON parsen und typisieren
    jsonSchema
  );
